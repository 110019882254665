import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  UserService,
  PeriodsService,
  UserActivityService,
  StatsProgressBarService,
  CountryLearnersService,
  LearnerAnalyticsService,
  ConversationService,
} from './services';

import {
  UserData,
  CountryLearners,
  StatsProgressBarData,
  UserActivityData,
  LearnerAnalyticsData,
  ConversationData,
} from '../data';

const SERVICES = [
  {
    provide: CountryLearners,
    useClass: CountryLearnersService,
  },
  {
    provide: StatsProgressBarData,
    useClass: StatsProgressBarService,
  },
  {
    provide: UserActivityData,
    useClass: UserActivityService,
  },
  {
    provide: UserData,
    useClass: UserService,
  },
  {
    provide: LearnerAnalyticsData,
    useClass: LearnerAnalyticsService,
  },
  {
    provide: ConversationData,
    useClass: ConversationService,
  },
  PeriodsService,
];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class MockDataModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: MockDataModule,
      providers: [...SERVICES],
    } as ModuleWithProviders<any>;
  }
}
