import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserData, User } from '../../data';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class UserService extends UserData {
  constructor(public auth: AngularFireAuth) {
    super();
  }

  getCurrentUser(): Observable<User> {
    return from(this.auth.currentUser).pipe(
      map((user) => {
        return {
          name: user.displayName,
          picture: user.photoURL,
        };
      }),
    );
  }
}
