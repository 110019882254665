import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { CountryLearners } from '../../data';

@Injectable()
export class CountryLearnersService extends CountryLearners {
  private countriesCategories = [
    'Project Management',
    'NCD Program',
    'Communication',
    'People Management',
    'Time Management',
  ];
  private countriesCategoriesLength = this.countriesCategories.length;
  private generateRandomData(nPoints: number): number[] {
    return Array.from(Array(nPoints)).map(() => {
      return Math.round(Math.random() * 20);
    });
  }

  getCountriesCategories(): Observable<string[]> {
    return observableOf(this.countriesCategories);
  }

  getCountriesCategoriesData(country: string): Observable<number[]> {
    return observableOf(
      this.generateRandomData(this.countriesCategoriesLength),
    );
  }
}
