import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { ProgressInfo, StatsProgressBarData } from '../../data';

@Injectable()
export class StatsProgressBarService extends StatsProgressBarData {
  private progressInfoData: ProgressInfo[] = [
    {
      title: 'Todays Learning Minutes',
      value: 5430,
      activeProgress: 70,
      description: 'Better than last week (70%)',
    },
    {
      title: 'New Learners',
      value: 5490,
      activeProgress: 60,
      description: 'Better than last week (30%)',
    },
    {
      title: 'New Courses',
      value: 5,
      activeProgress: 55,
      description: 'Better than last week (55%)',
    },
  ];

  getProgressInfoData(): Observable<ProgressInfo[]> {
    return observableOf(this.progressInfoData);
  }
}
