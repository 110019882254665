import { Component, OnInit } from '@angular/core';
import { NbLoginComponent, NbUser } from '@nebular/auth';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends NbLoginComponent implements OnInit {
  user: NbUser;

  ngOnInit(): void {
    // this.user.email = 'kariscomp@gmail.com';
    // this.user.password = '123456';
    // this.user.rememberMe = false;
  }
}
