import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ConversationData,
  ConversationUsers,
  UserSnapProfile,
} from '../../data';

@Injectable()
export class ConversationService extends ConversationData {
  private conversationUsers: ConversationUsers[] = [
    {
      name: 'Peter Kariuki',
      picture: 'assets/images/peter.jpg',
      lastSeen: new Date(),
      moto: 'I can learn anything',
    },
    {
      name: 'Eva Moor',
      picture: 'assets/images/eva.png',
      lastSeen: new Date(),
      moto: 'I can learn anything',
    },
    {
      name: 'Jack Williams',
      picture: 'assets/images/jack.png',
      lastSeen: new Date(),
      moto: 'I can learn anything',
    },
    {
      name: 'Lee Wong',
      picture: 'assets/images/lee.png',
      lastSeen: new Date(),
      moto: 'I can learn anything',
    },
    {
      name: 'Alan Thompson',
      picture: 'assets/images/alan.png',
      lastSeen: new Date(),
      moto: 'I can learn anything',
    },
    {
      name: 'Kate Martinez',
      picture: 'assets/images/kate.png',
      lastSeen: new Date(),
      moto: 'I can learn anything',
    },
  ];

  private userSnapProfile: UserSnapProfile = {
    stats: [
      {
        title: 'Minutes',
        value: 120,
        activeProgress: 30,
      },
      {
        title: 'Attendance',
        value: 80,
        activeProgress: 60,
      },
      {
        title: 'Progress',
        value: 60,
        activeProgress: 50,
      },
    ],
    learningProgress: [
      {
        title: 'Project Management',
        value: 100,
        activeProgress: 60,
      },
      {
        title: 'People Management',
        value: 100,
        activeProgress: 60,
      },
    ],
  };

  getUserContacts(): Observable<ConversationUsers[]> {
    return observableOf(this.conversationUsers);
  }

  getUserRecentContacts(): Observable<ConversationUsers[]> {
    return observableOf(this.conversationUsers);
  }

  getUserSnapProfile(): Observable<UserSnapProfile> {
    return observableOf(this.userSnapProfile);
  }
}
