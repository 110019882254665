import { Observable } from 'rxjs';
import { User } from './';

export interface ConversationUsers extends User {
  lastSeen: Date;
  moto: string;
}

export interface LearningProgress {
  title: string;
  value: number;
  activeProgress: number;
}

export interface UserSnapProfile {
  stats: LearningProgress[];
  learningProgress: LearningProgress[];
}

export abstract class ConversationData {
  abstract getUserContacts(): Observable<ConversationUsers[]>;
  abstract getUserRecentContacts(): Observable<ConversationUsers[]>;
  abstract getUserSnapProfile(): Observable<UserSnapProfile>;
}
