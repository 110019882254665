import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService, LayoutService } from './utils';

import { MockDataModule } from './mock/mock-data.module';
import { RoleProvider } from './utils';
import {
  NbFirebasePasswordStrategy,
  NbFirebaseAuthModule,
} from '@nebular/firebase-auth';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  NbFirebasePasswordStrategy,
  {
    provide: NbRoleProvider,
    useClass: RoleProvider,
  },
  AnalyticsService,
  LayoutService,
];

@NgModule({
  imports: [
    CommonModule,
    NbFirebaseAuthModule,
    NbAuthModule.forRoot({
      strategies: [
        NbFirebasePasswordStrategy.setup({
          name: 'email',
          login: {
            redirect: {
              success: '/app/', // welcome page path
              failure: null, // stay on the same page
            },
          },
          register: {
            redirect: {
              success: '/app/', // welcome page path
              failure: null, // stay on the same page
            },
          },
        }),
      ],
      forms: {},
    }),
    NbSecurityModule.forRoot({
      accessControl: {
        guest: {
          view: ['auth'],
        },
        user: {
          parent: 'guest',
          view: '*',
          create: '*',
          edit: '*',
          remove: '*',
        },
      },
    }),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
