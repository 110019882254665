<h1 id="title" class="title">Change password</h1>
<p class="sub-title">Please set a new password</p>

<nb-alert
  *ngIf="showMessages.error && errors?.length && !submitted"
  outline="danger"
  role="alert"
>
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert
  *ngIf="showMessages.success && messages?.length && !submitted"
  outline="success"
  role="alert"
>
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">
      {{ message }}
    </li>
  </ul>
</nb-alert>

<form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-password">New Password:</label>
    <input
      nbInput
      [(ngModel)]="user.password"
      #password="ngModel"
      type="password"
      id="input-password"
      name="password"
      class="first"
      placeholder="New Password"
      autofocus
      fullWidth
      fieldSize="large"
      [status]="
        password.dirty ? (password.invalid ? 'danger' : 'success') : 'basic'
      "
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null"
    />
    <ng-container *ngIf="password.invalid && password.touched">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p
        class="caption status-danger"
        *ngIf="password.errors?.minlength || password.errors?.maxlength"
      >
        Password should contains from
        {{ getConfigValue('forms.validation.password.minLength') }} to
        {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-group">
    <label class="label" for="input-re-password">Confirm Password:</label>
    <input
      nbInput
      [(ngModel)]="user.confirmPassword"
      #rePass="ngModel"
      id="input-re-password"
      name="rePass"
      type="password"
      class="last"
      placeholder="Confirm Password"
      fullWidth
      fieldSize="large"
      [status]="
        rePass.touched
          ? rePass.invalid || password.value != rePass.value
            ? 'danger'
            : 'success'
          : 'basic'
      "
      [required]="getConfigValue('forms.validation.password.required')"
      [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
    />
    <ng-container *ngIf="rePass.touched">
      <p
        class="caption status-danger"
        *ngIf="rePass.invalid && rePass.errors?.required"
      >
        Password confirmation is required!
      </p>
      <p
        class="caption status-danger"
        *ngIf="password.value != rePass.value && !rePass.errors?.required"
      >
        Password does not match the confirm password.
      </p>
    </ng-container>
  </div>

  <button
    nbButton
    status="primary"
    fullWidth
    size="large"
    [disabled]="submitted || !resetPassForm.valid"
    [class.btn-pulse]="submitted"
  >
    Change password
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Back to Log In</a></p>
  <p><a class="text-link" routerLink="../register">Register</a></p>
</section>
